import React from 'react';
import PropTypes from 'prop-types';

const Spinner = ( { className, loadingText } ) => {
	return (
		<div
			className={ className }
		>
			<div className="spinner"></div>
			<p>{ loadingText }</p>
		</div>
	);
};

Spinner.defaultProps = {
	className: '',
	loadingText: 'loading...'
};

Spinner.propTypes = {
	className: PropTypes.string,
	loadingText: PropTypes.string
};

export default Spinner;
