import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Image from './Image';

import projectGalleryStyles from './project-gallery.module.css';

const TwoColumns = ( { image, image2, setImage } ) => {
	const handleImageClick = useCallback( () => setImage( image ), [ image, setImage ] );
	const handleImage2Click = useCallback( () => setImage( image2 ), [ image2, setImage ] );

	return (
		<div className={ `${ projectGalleryStyles.twoColumns } ${ projectGalleryStyles.row }` }>
			<Image
				className={ `${ projectGalleryStyles.image } ${ projectGalleryStyles.left }` }
				image={ image }
				onClick={ handleImageClick }
			/>
			<Image
				className={ `${ projectGalleryStyles.image } ${ projectGalleryStyles.right }` }
				image={ image2 }
				onClick={ handleImage2Click }
			/>
		</div>
	);
};

TwoColumns.propTypes = {
	image: PropTypes.shape( {
		src: PropTypes.object,
		title: PropTypes.string,
		alt: PropTypes.string,
		credit: PropTypes.string
	} ).isRequired,
	image2: PropTypes.shape( {
		src: PropTypes.object,
		title: PropTypes.string,
		alt: PropTypes.string,
		credit: PropTypes.string
	} ).isRequired,
	setImage: PropTypes.func.isRequired
};

export default TwoColumns;
