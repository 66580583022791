import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Image from './Image';

import projectGalleryStyles from './project-gallery.module.css';

const FullWidth = ( { image, setImage } ) => {
	const handleImageClick = useCallback( () => setImage( image ), [ image, setImage ] );

	return (
		<div className={ `${ projectGalleryStyles.fullWidth } ${ projectGalleryStyles.row }` }>
			<Image
				className={ projectGalleryStyles.image }
				image={ image }
				onClick={ handleImageClick }
			/>
		</div>
	);
};

FullWidth.propTypes = {
	image: PropTypes.shape( {
		src: PropTypes.object,
		title: PropTypes.string,
		alt: PropTypes.string,
		credit: PropTypes.string
	} ).isRequired,
	setImage: PropTypes.func.isRequired
};

export default FullWidth;
