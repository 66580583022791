import React, { forwardRef, useCallback, useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import Spinner from '../Spinner';

import styles from './lazy-loaded-image.module.css';

const LazyLoadedImage = ( { alt, className, credit, src, title }, ref ) => {
	const [ loading, setLoading ] = useState( true );
	const containerRef = useRef( null );
	const imgRef = useRef( null );

	useLayoutEffect( () => {
		setLoading( true );
		containerRef.current.style.opacity = 0;
	}, [ src ] );

	const onLoad = useCallback( () => {
		setLoading( false );
		containerRef.current.style.opacity = 1;
	}, [] );

	return (
		<>
			{ loading
				? <Spinner
					className={ styles.spinner }
					loadingText="Loading Image..."
				/>
				: null
			}
			<div
				className={ `${ styles.image } ${ className }` }
				ref={ containerRef }
			>
				<figure>
					<img
						alt={ alt }
						onLoad={ onLoad }
						ref={ imgRef }
						src={ src }
					/>
					{ credit ? <figcaption>{ credit }</figcaption> : null }
					<p className={ styles.title }>{ title }</p>
				</figure>
			</div>
		</>
	);
};

LazyLoadedImage.propTypes = {
	alt: PropTypes.string,
	className: PropTypes.string,
	credit: PropTypes.string,
	src: PropTypes.string.isRequired,
	title: PropTypes.string
};

export default forwardRef( LazyLoadedImage );
