import React from 'react';
import PropTypes from 'prop-types';

import Spinner from '../Spinner';

import projectGalleryStyles from './project-gallery.module.css';

const CanvaEmbed = ( { embedCode } ) => {
	return (
		<div className={ `${ projectGalleryStyles.CanvaEmbed } ${ projectGalleryStyles.row }` }>
			<div style={ {
				position: 'relative',
				width: '100%',
				height: 0,
				paddingTop: '56.2500%',
				paddingBottom: '48px',
				boxShadow: '0 2px 8px 0 rgba(63,69,81,0.16)',
				overflow: 'hidden',
				willChange: 'transform'
			} }
			>
				<p
					style={ {
						position: 'absolute',
						top: 0,
						right: 0,
						bottom: 0,
						left: 0,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						padding: 0,
						margin: 'auto'
					} }>
					<Spinner
						className={ projectGalleryStyles.spinner }
						loadingText=""
					/>
				</p>
				<iframe
					style={ {
						position: 'absolute',
						width: '100%',
						height: '100%',
						top: 0,
						left: 0,
						border: 'none',
						padding: 0,
						margin: 0
					} }
					src={ `${ embedCode }/view?embed` }
					title="canva_embed"
				/>
			</div>
		</div>
	);
};

CanvaEmbed.propTypes = { embedCode: PropTypes.string };

export default CanvaEmbed;
