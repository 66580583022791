import ReactDOM from 'react-dom';
import { usePortal } from '../../hooks/usePortal';
import './Modal.css';

const Modal = ( { children, closeModal } ) => {
	const targetNode = usePortal( { closeModal } );

	return ReactDOM.createPortal( children, targetNode );
};

export default Modal;
