import { useEffect, useRef } from 'react';

import Close from '../images/close.svg';

export const usePortal = ( { closeModal } ) => {
	// create div and add the class of modal
	const modal = document.createElement( 'div' );

	modal.setAttribute( 'class', 'modal' );

	// get a ref to the newly created div
	const modalRef = useRef( modal );

	// create the modalCLoseIcon and add a src, an alt, and a class of close-modal
	const closeModalIcon = document.createElement( 'img' );

	closeModalIcon.src = Close;
	closeModalIcon.alt = '';
	closeModalIcon.classList.add( 'close-modal' );

	useEffect( () => {
		const modal = modalRef.current;

		// disable scroll on the body
		const { documentElement, body } = document;

		documentElement.setAttribute( 'style', 'overflow: hidden; -webkit-overflow-scrolling: touch; pointer-events: none' );
		body.setAttribute( 'style', 'overflow: hidden; -webkit-overflow-scrolling: touch; pointer-events: none' );

		// append the modal to the modal root element
		const modalRoot = document.querySelector( '#modal-root' );

		modalRoot.appendChild( modal );

		// append the closeModalIcon to the modal and add a click event
		modal.appendChild( closeModalIcon );

		closeModalIcon.addEventListener( 'click', closeModal );

		return () => {
			// documentElement.style.cssText = 'position: initial; overflow: initial';
			documentElement.removeAttribute( 'style' );
			body.removeAttribute( 'style' );
			closeModalIcon.removeEventListener( 'click', closeModal );
			closeModalIcon.remove();
			modal.remove();
		};
	}, [ closeModal, closeModalIcon ] );

	return modalRef.current;
};
