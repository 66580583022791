import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import CanvaEmbed from './CanvaEmbed';
import FullWidth from './FullWidth';
import InstagramEmbed from './InstagramEmbed';
import LazyLoadedImage from '../../components/LazyLoadedImage';
import Modal from '../../components/Modal';
import TwoColumns from './TwoColumns';
import ThreeColumns from './ThreeColumns';

import PrevArrow from '../../images/prevArrow.svg';
import NextArrow from '../../images/nextArrow.svg';

import projectGalleryStyles from './project-gallery.module.css';

const ProjectGallery = ( { className, images } ) => {
	const [ image, setImage ] = useState( false );
	const [ imageList, setImageList ] = useState( [] );

	const loadedImages = useRef( [] );
	const prevRef = useRef( null );
	const nextRef = useRef( null );

	const mapImages = useCallback( () => images.map( ( row, i ) => {
		switch ( row.type ) {
			case 'full_width':
				return (
					<FullWidth
						key={ i }
						image={ row.image }
						setImage={ setImage }
					/>
				);
			case 'two_columns':
				return (
					<TwoColumns
						key={ i }
						image={ row.image }
						image2={ row.image2 }
						setImage={ setImage }
					/>
				);
			case 'three_columns':
				return (
					<ThreeColumns
						key={ i }
						image={ row.image }
						image2={ row.image2 }
						image3={ row.image3 }
						setImage={ setImage }
					/>
				);
			case 'canva_embed':
				return (
					<CanvaEmbed
						key={ i }
						embedCode={ row.embedCode }
					/>
				);
			case 'instagram_embed':
				return (
					<InstagramEmbed
						key={ i }
						account={ row.account }
						embedCode={ row.embedCode }
						handle={ row.handle }
					/>
				);
			default:
				return null;
		}
	} ), [ images ] );

	useEffect( () => {
		images.forEach( row => {
			if ( row.type === 'canva_embed' || row.type === 'instagram_embed' ) { // exclude canva embeds and instagram embeds for now
				return;
			}

			setImageList( prevState => {
				const imgs = row.image3
					? [
						{ ...row.image, type: row.type },
						{ ...row.image2, type: row.type },
						{ ...row.image3, type: row.type }
					]
					: row.image2
						? [
							row.image2,
							row.image
						]
						: [ row.image ];

				return [
					...prevState,
					...imgs
				];
			} );
		} );
	}, [ images ] );

	const prevImage = useCallback( () => {
		// modalImageRef.current.style.opacity = 0;
		let curIndex = imageList.findIndex( img => img.src.childImageSharp.full.src === image.src.childImageSharp.full.src );

		if ( curIndex === 0 ) {
			setImage( imageList[ imageList.length - 1 ] );
		} else {
			setImage( imageList[ curIndex - 1 ] );
		}
	}, [ image, imageList ] );

	const nextImage = useCallback( () => {
		// modalImageRef.current.style.opacity = 0;
		let curIndex = imageList.findIndex( img => img.src.childImageSharp.full.src === image.src.childImageSharp.full.src );

		if ( curIndex + 1 < imageList.length ) {
			setImage( imageList[ curIndex + 1 ] );
		} else {
			setImage( imageList[ 0 ] );
		}
	}, [ image, imageList ] );

	return (
		<div id="project-gallery" className={ `${ projectGalleryStyles.projectGallery } ${ className }` }>
			{ mapImages() }
			{ image
				? <Modal
					closeModal={ () => {
						setImage( null );
					} }
				>
					<div
						className={ projectGalleryStyles.imageModal }
					>
						{ typeof image === 'string'}
						<LazyLoadedImage
							alt={ image.alt }
							className={ projectGalleryStyles.fullImage }
							credit={ image.credit }
							ref={ loadedImages }
							src={ image.src.childImageSharp.full.src }
							title={ image.title }
						/>
						{ imageList.length > 1
							? <>
								<img
									alt=""
									className={ projectGalleryStyles.prev }
									onClick={ prevImage }
									ref={ prevRef }
									src={ PrevArrow }
								/>
								<img
									alt=""
									className={ projectGalleryStyles.next }
									onClick={ nextImage }
									ref={ nextRef }
									src={ NextArrow }
								/>
							</>
							: null
						}
					</div>
				</Modal>
				: null
			}
		</div>
	);
};

ProjectGallery.defaultProps = { className: '' };

ProjectGallery.propTypes = {
	className: PropTypes.string,
	images: PropTypes.array.isRequired
};

export default memo( ProjectGallery );
