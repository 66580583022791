import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

const Image = ( {
	image,
	...props
} ) => {
	return (
		<>
			<span
				{ ...props }
			>
				<Img
					alt={ image.alt }
					backgroundColor="#dedede"
					fluid={ image.src.childImageSharp.thumbnail }
					placeholderStyle={ { opacity: 0 } }
				/>
			</span>
		</>
	);
};

Image.propTypes = {
	image: PropTypes.shape( {
		src: PropTypes.object,
		title: PropTypes.string,
		alt: PropTypes.string,
		credit: PropTypes.string
	} ).isRequired
};

export default Image;
