import React, { useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import ProjectGallery from '../components/ProjectGallery';
import SEO from '../components/SEO';

import projectPageStyles from './project-page.module.css';

export const ProjectPageTemplate = ( {
	title,
	year,
	category,
	description,
	images
} ) => {
	const articleRef = useRef( null );

	useLayoutEffect( () => {
		const article = articleRef.current;

		if ( article ) {
			const articleHeight = article.offsetHeight;

			const onResize = () => {
				if ( window.innerWidth < 1024 ) {
					return;
				}

				const headerHeight = 7.375 * 16;
				const padding = 40;

				if ( articleHeight > window.innerHeight - ( headerHeight + padding ) ) {
					article.style.top = `calc(9.875rem - calc(${ articleHeight }px - calc(100vh - 12.275rem)))`;
				} else {
					article.style.top = 'calc(7.375rem + 2.5rem)';
				}
			};

			onResize();

			window.addEventListener( 'resize', onResize );

			return () => {
				window.removeEventListener( 'resize', onResize );
			};
		}

		return () => null;
	}, [] );

	return (
		<>
			<Layout links={ [ { text: '◂ back to projects', to: '/projects' } ] }>
				<SEO title="Home" />
				<div className="wrapper page-content">
					<article
						className={ `left-page-column ${ projectPageStyles.projectInfo }` }
						ref={ articleRef }
					>
						<header className={ projectPageStyles.header }>
							<h5 className={ projectPageStyles.category }>{ category }&nbsp;({ year })</h5>
							<h1 className={ projectPageStyles.title }>
								{ title }{/* &nbsp;<small className={ projectPageStyles.year }>({year})</small> */}
							</h1>
						</header>
						<main className={ projectPageStyles.description } dangerouslySetInnerHTML={ { __html: description } } />
					</article>
					<ProjectGallery className={ `right-page-column ${ projectPageStyles.gallery }` } images={ images || [] }/>
				</div>
				{/* <p style={{ fontSize: '0.5rem', fontWeight: 300, textAlign: 'right' }}><a href="#top">&uarr; back to top</a></p> */}
			</Layout>
		</>
	);
};

ProjectPageTemplate.propTypes = {
	title: PropTypes.string.isRequired,
	year: PropTypes.string.isRequired,
	category: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	images: PropTypes.array.isRequired
};

const ProjectPage = ( { data } ) => {
	const { frontmatter, html } = data.markdownRemark;

	return (
		<ProjectPageTemplate
			title={ frontmatter.title }
			year={ frontmatter.year }
			category={ frontmatter.category }
			description={ html }
			images={ frontmatter.images }
		/>
	);
};

ProjectPage.propTypes = {
	pageContext: PropTypes.object,
	data: PropTypes.object
};

export default ProjectPage;

export const pageQuery = graphql`
	query ProjectByID($id: String!) {
		markdownRemark(id: {eq: $id}) {
			frontmatter {
				title
				year
				category
				images {
					type
					embedCode
					image {
						src {
							childImageSharp {
								thumbnail: fluid(maxWidth: 1536, quality: 50) {
									...GatsbyImageSharpFluid
								}
								full: fluid (maxWidth: 2624, quality: 100) {
									...GatsbyImageSharpFluid
								}
							}
						}
						title
						alt
						credit
					}
					image2 {
						src {
							childImageSharp {
								thumbnail: fluid(maxWidth: 1536, quality: 50) {
									...GatsbyImageSharpFluid
								}
								full: fluid (maxWidth: 2624, quality: 100) {
									...GatsbyImageSharpFluid
								}
							}
						}
						title
						alt
						credit
					}
					image3 {
						src {
							childImageSharp {
								thumbnail: fluid(maxWidth: 1536, quality: 50) {
									...GatsbyImageSharpFluid
								}
								full: fluid (maxWidth: 2624, quality: 100) {
									...GatsbyImageSharpFluid
								}
							}
						}
						title
						alt
						credit
					}
				}
			}
			html
		}
	}
`;
